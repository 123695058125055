<template>
  <div>
    <!-- Start hero #1-->
    <section class="hero hero-mailchimp bg-primary-alt">
      <div class="container">
        <div class="hero-cotainer">
          <div class="row">
            <div class="col-12 col-md-6 col-lg-6">
              <div class="hero-content">
                <h1 class="hero-headline">{{ naskah.title }}</h1>
                <div class="hero-bio">
                  <strong>Kepengarangan :</strong> {{ naskah.author }}<br />
                  <strong>Penerbit :</strong> {{ naskah.user.type }} {{ naskah.user.name }}
                </div>
                <h5 style="color:#FF7101"><i class="fa-sharp fa-solid fa-qrcode"></i> {{ naskah.qrcbn_id }}</h5>
              </div>
            </div>
            <div class="col-12 col-md-6 col-lg-6"><img class="img-fluid float-right" :src="naskah.cover_url"
                :alt="naskah.title" /></div>
          </div>
          <!-- End .row-->
        </div>
        <!-- End .hero-cotainer-->
      </div>
      <!-- End .container-->
      <div class="divider-shape-bottom"><svg viewBox="0 0 1920 250" xmlns="http://www.w3.org/2000/svg"
          style="background: #2D36A1" width="100%" preserveAspectRatio="none">
          <path fill="rgb(244, 246, 249, 0.02)"
            d="M1920 250H0V0s126.707 78.536 349.975 80.05c177.852 1.203 362.805-63.874 553.803-63.874 290.517 0 383.458 57.712 603.992 61.408 220.527 3.696 278.059-61.408 412.23-17.239">
          </path>
          <path fill="rgb(244, 246, 249, 0.08)"
            d="M1920 144s-467.917 116.857-1027.243-17.294C369.986 1.322 0 45.578 0 45.578V250h1920V144z"></path>
          <path fill="#FFFFFF"
            d="M0 195.553s208.547-75.581 701.325-20.768c376.707 41.908 520.834-67.962 722.545-67.962 222.926 0 311.553 83.523 496.129 86.394V250H0v-54.447z">
          </path>
        </svg>
      </div>
    </section>
    <!-- End #hero-->

    <!-- 
      About 
      =============================================  
      -->
    <section class="about" id="about">
      <div class="container">
        <div class="row align-items-center h-100">
          <div class="col-12 col-md-12 col-lg-12">
            <div class="heading">
              <h2 class="heading-title">Tentang Buku Ini</h2>
            </div>
            <div class="row about-list">
              <div class="col-6 col-md-6 col-lg-2">
                <div class="about-item style-1">
                  <div class="about-item-icon">
                    <i class="fa-solid fa-calendar-day"></i>
                  </div>
                  <div class="about-item-title">
                    <div><span class="counting">Tanggal Terbit</span></div>
                    <div>{{ naskah.publish_date }}</div>
                  </div>
                </div>
              </div>
              <div class="col-6 col-md-6 col-lg-2">
                <div class="about-item style-2">
                  <div class="about-item-icon">
                    <i class="fa-solid fa-swatchbook"></i>
                  </div>
                  <div class="about-item-title">
                    <div><span class="counting">Jumlah Halaman</span></div>
                    <div>{{ naskah.page }} Halaman</div>
                  </div>
                </div>
              </div>
              <div class="col-6 col-md-6 col-lg-1">
                <div class="about-item style-3">
                  <div class="about-item-icon">
                    <i class="fa-solid fa-receipt"></i>
                  </div>
                  <div class="about-item-title">
                    <div><span class="counting">Edisi</span></div>
                    <div>{{ naskah.edisi }}</div>
                  </div>
                </div>
              </div>

              <div class="col-6 col-md-6 col-lg-1">
                <div class="about-item style-1">
                  <div class="about-item-icon">
                    <i class="fa-solid fa-bookmark"></i>
                  </div>
                  <div class="about-item-title">
                    <div><span class="counting">Seri</span></div>
                    <div>{{ naskah.seri }}</div>
                  </div>
                </div>
              </div>

              <div class="col-6 col-md-6 col-lg-2">
                <div class="about-item style-2">
                  <div class="about-item-icon">
                    <i class="fa-solid fa-swatchbook"></i>
                  </div>
                  <div class="about-item-title">
                    <div><span class="counting">Pembaca</span></div>
                    <div>{{ naskah.readers }}</div>
                  </div>
                </div>
              </div>

              <div class="col-6 col-md-6 col-lg-2">
                <div class="about-item style-3">
                  <div class="about-item-icon">
                    <i class="fa-solid fa-receipt"></i>
                  </div>
                  <div class="about-item-title">
                    <div><span class="counting">Kategori</span></div>
                    <div>{{ naskah.category }}</div>
                  </div>
                </div>
              </div>

              <div class="col-6 col-md-6 col-lg-2">
                <div class="about-item style-1">
                  <div class="about-item-icon">
                    <i class="fa-solid fa-bookmark"></i>
                  </div>
                  <div class="about-item-title">
                    <div><span class="counting">Media Terbit</span></div>
                    <div>{{ naskah.publish_type }}</div>
                  </div>
                </div>
              </div>

              <div class="col-12">
                <h5>Deskripsi Buku</h5>
                <p class="sinopsis-naskah">
                  {{ naskah.description }}
                </p>
              </div>

            </div>
          </div>
        </div>
        <!-- End .row  -->
      </div>
      <!-- End .container  -->
    </section>
    <!-- End #banner2  -->

    <!-- 
      CTA #1
      =============================================  
      -->
    <section class="cta pt-0 pb-0" id="action">
      <div class="container">
        <div class="row">
          <div class="col-12 col-md-12 col-lg-12">
            <div class="action-container">
              <div class="row align-items-center h-100">
                <div class="col-12 col-md-4 col-lg-4">
                  <div class="cta-cover"><img class="img-fluid" :src="naskah.cover_url" :alt="naskah.title" /></div>
                </div>
                <div class="col-12 col-md-8 col-lg-8">
                  <h3>Penasaran Isi Lengkapnya? <br/> Beli Sekarang.</h3>
                  <div class="market-btn">
                    <a v-for="(link, index) in naskah.link" :key="index" :href="link.link" target="_blank" rel="noopener noreferrer">
                      <button class="btn btn--secondary">
                        <span>{{ link.platform }}</span>
                      </button>
                    </a>
                  </div>
                </div>
                <!-- End .col-md-12-->
              </div>
              <!-- End .row-->
            </div>
          </div>
        </div>
      </div>
      <!-- End .container-->
    </section>
    <!-- End #cta-->

  </div>
</template>

<script>
import axios from 'axios'
export default {
  components: {

  },
  data() {
    return {
      loading_data: false,

      naskah: [],
    }
  },

  methods: {

    getNaskah() {

      this.loading_data = true
      var id = this.$route.params.id

      axios.get('naskah/' + id, {
        headers: {
          "Access-Control-Allow-Headers": "*",
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        }
      })
        .then(response => {
          this.naskah = response.data.data.naskah
        })
        .catch(error => {
          console.log(error)
          this.loading_data = false
        })
        .finally(() => (this.loading_data = false))

    }

  },

  mounted() {
    this.getNaskah()
  },

  computed: {
    noData() {
      return this.naskah == null;
    }
  },
}

</script>

<style>
.about-item-icon i{
  font-size: 3em;
}
.sinopsis-naskah{
  white-space: break-spaces;
  font-size: 1.1em;
}
.market-btn{
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
}
</style>
